//axiosInstance.js
//导入axios
import axios from 'axios'
import {ElMessage} from 'element-plus'
import store from '@/state/store'
//使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
const service = axios.create({
    baseURL: process.env.VUE_APP_SERVER, //请求后端数据的基本地址，自定义
    timeout: 10000,                   //请求超时设置，单位ms
    headers: {
        // 设置后端需要的传参类型
        'Content-Type': 'application/json',
    }
})

const needTokenURL = ['/api/visa/AddVisaCase',
    '/api/visa/UpdateVisaCase',
    '/auth/user/password/change',
    '/api/visa/queryMyFavouredVisaCase/',
    '/api/visa/query-my-visa-case',
    '/api/visa/FavouredVisaCase/',
    '/api/visa/UnFavouredVisaCase/']
// 添加请求拦截器
service.interceptors.request.use(
    function (config) {
        console.log('config:', config)
        let authpage = false
        for (let i = 0; i < needTokenURL.length; i++) {
            authpage = config.url.includes(needTokenURL[i]);
            if (authpage == true)
                break;
        }
        const userInfo = store.getters['auth/getCurrentUser']
        console.log('222222==', userInfo, typeof userInfo, authpage)
        if (authpage && userInfo) {
            console.log('设置token----------')
            config.headers.Authorization = 'Bearer ' + userInfo.token
        }
        // 在发送请求之前做些什么
        return config
    },
    function (error) {
        // 对请求错误做些什么
        console.log(error)
        return Promise.reject(error)
    }
)

// 添加响应拦截器
service.interceptors.response.use(
    function (response) {
        console.log('response:', response)
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data
        console.log('dataAxios:', dataAxios)
        // 这个状态码是和后端约定的
        const code = dataAxios.code
        // 错误弹出
        if (code != 200) {
            console.log('errorrrrrr:', dataAxios.data)
            if (code == 453) {
                // 用户未激活，跳转到激活页面
                return Promise.reject(dataAxios)
            } else {
                ElMessage.error(dataAxios.data || dataAxios.message)
                return Promise.reject(dataAxios.data || dataAxios.message)
            }
        } else {
            return dataAxios.data;
        }
    },
    function (error) {
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        console.log('error:', error)
        return Promise.reject(error)
    }
)
//导出我们建立的axios实例模块，ES6 export用法
export default service