import {createApp} from 'vue'

import App from './App.vue'
import router from "./router";
import BootstrapVue3 from 'bootstrap-vue-3';
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import {registerScrollSpy} from 'vue3-scroll-spy';

import axios from '@/plugins/axios.js'
import {vMaska} from "maska"
import {i18n} from "./i18n.js"
import {initFirebaseBackend} from './authUtils'
import {configureFakeBackend} from './helpers/fake-backend';

import BootstrapVueNext from 'bootstrap-vue-next'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import "@/assets/scss/app.scss";
import "@vueform/multiselect/themes/default.css"

// PINIA
import pinia from '@/state/pinia'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {ElMessage} from 'element-plus'
import store from "./state/store";

const app = createApp(App)
    .use(pinia)
    .use(pinia)
    .use(store)
    .use(router)
    .use(require('vue-chartist'))
    .use(BootstrapVueNext).use(BootstrapVue3)
    .use(VueApexCharts)
    .use(vClickOutside)
    .use(i18n)
    .use(registerScrollSpy)
    .use(ElementPlus)
    .directive("maska", vMaska)

app.config.globalProperties.$axios = axios;  //配置axios的全局引用
app.config.globalProperties.$message = ElMessage;

app.mount('#app');


console.log("ENV>>>> ", process.env.NODE_ENV);
console.log("APP SEVER>>>> ", process.env.VUE_APP_SERVER);

// Date prototype
Date.prototype.DateFormat = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours() % 12 == 0 ? 12 : this.getHours() % 12, //小时
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    var week = {
        "0": "/u65e5",
        "1": "/u4e00",
        "2": "/u4e8c",
        "3": "/u4e09",
        "4": "/u56db",
        "5": "/u4e94",
        "6": "/u516d"
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" : "/u5468") : "") + week[this.getDay() + ""]);
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}